#competences {
  padding-top: 80px;
}

#competences .row {
  text-align: center;
}

.card {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 20px 15px;
  max-width: 250px;
  width: 100%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 0.75em;
  padding: 0;
}

.card-top {
  padding: 2em;
  border-top-left-radius: .75em;
  border-top-right-radius: .75em;

  .card-img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 10em;
    width: auto;
  }
}

.card-middle {
  background: #efefef;
  padding: 2em 1em 1em 1em;
  overflow: hidden;
  transition: max-height 0.8s ease;
  border-bottom-left-radius: .75em;
  border-bottom-right-radius: .75em;
  
  .more {
    font-weight: 400;
  }
}

.triangle {
  position: relative;
  width: 0;
  height: 0;
  border-left: 1.5em solid transparent;
  border-right: 1.5em solid transparent;
  border-top: 1.5em solid #B2DCE5;
  margin: 0 auto -1.75em auto;
  top: -.25em;
  z-index: 20;
}

.circle-button {
  position: absolute;
  top: 190px;
  left: 15px;
  bottom: 0;
  right: 3.5em;
  width: 50px;
  height: 50px;
  font-size: 1.5em;
  color: #fff;
  background: #fff;
  border: none;
  border-radius: 50%;
  box-shadow: 0 3px 6px rgba(0,0,0,.275);
  outline: none;
  cursor: pointer;
  transition: all 300ms ease;
  z-index: 20;
  
  .close {
    transition: transform 400ms ease;
  }
  
  &:hover {
    box-shadow: 0 6px 12px rgba(0,0,0,.275);
  }
}

.closeRotate {
  transform: rotate(45deg);
}
