header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 15px 0;
  z-index: 1000;
}
  
.container {
  width: 80%;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
  
nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}
  
nav li {
  margin-right: 20px;
}
  
nav a {
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  position: relative;
  transition: color 0.3s ease;
}
  
nav a:hover {
  color: #ffcc00;
}
  
nav a::before {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #ffcc00;
  transform: scaleX(0);
  transform-origin: bottom center;
  transition: transform 0.3s ease;
}
  
nav a:hover::before {
  transform: scaleX(1);
  transform-origin: bottom center;
}

h1 {
  margin: 0;
}
