footer {
    background-color: #333;
    color: #fff;
    padding: 20px 0;
    margin-top: 150px;
  }
  
  .containerFooter {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .social-icons a {
    color: #fff;
    font-size: 18px;
    margin-right: 20px;
    text-decoration: none;
  }
  
  .footer-text {
    font-size: 14px;
  }
  
  @media (max-width: 768px) {
    .containerFooter {
      flex-direction: column;
      align-items: center;
    }
  
    .social-icons {
      margin-bottom: 20px;
    }
  }
  