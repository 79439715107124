#portfolio {
  padding-top: 90px;
}

.portfolio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.card-background {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 15px 15px;
  background-color: #ededed;
}
  
.card-portfolio {
  box-sizing: border-box;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: 100px;
  border-radius: 3px;
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  overflow: hidden;
  transition: width 325ms 75ms cubic-bezier(0.4, 0.0, 0.2, 1), height 325ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
  
.card-portfolio.full {
  width: 350px;
  height: 350px;
  transition: width 325ms cubic-bezier(0.4, 0.0, 0.2, 1), height 325ms 75ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
  
.card-portfolio.full .description {
  opacity: 1;
  transition: 325ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
  
.card-portfolio .image {
  display: block;
  cursor: pointer;
  height: 210px;
  background-size: cover;
  background-clip: padding-box;
  border-radius: 3px 3px 0 0;
  background-position: 30%;
}
  
.card-portfolio .description {
  display: flex;
  flex-direction: column;
  padding: 35px;
  padding-top: 20px;
  overflow: hidden;
  opacity: 0;
  transition: 325ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.card-portfolio .description .text-1 {
  padding-bottom: 20px;
}

.card-portfolio .description .links {
  display: flex;
  justify-content: space-between;
}

.card-portfolio .description .links a {
  text-align: center;
  text-decoration: none;
  color: grey;
}

.card-portfolio .description .links a:hover {
  color: black;
}
