#contact {
  padding-top: 70px;
}

.containerContact{
  max-width: 800px;
  background: #fff;
  width: 80%;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  border-radius: 1em;
  margin: 30px auto;
}

.containerContact .text{
  text-align: center;
  font-size: 41px;
  font-weight: 600;
  background: -webkit-linear-gradient(right, #ffcc00, #ff923c, #ffcc00, #ff923c);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.containerContact form{
  padding: 30px 0 0 0;
}

.containerContact form .form-row{
  display: flex;
  margin: 32px 0;
}

form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}

form .form-row .textarea{
  height: 70px;
}

.input-data input,
.textarea textarea{
  display: block;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
  outline: none !important;
}

.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label,
.input-data input[data-filled="true"] ~ label {
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
}

.textarea textarea{
  resize: none;
  padding-top: 10px;
}

.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
}

.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 101%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
  bottom: -4px;
}

.textarea .underline:before{
  bottom: -14px;
}

.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before,
.input-data input[data-filled="true"] ~ .underline:before {
  transform: scale(1);
}

.submit-btn {
  display: flex;
  justify-content: center;
  padding-bottom: 50px;
}

.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 25%!important;
}

.submit-btn .input-data .inner{
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  background: -webkit-linear-gradient(right, #ffbf00, #ff923c, #ffbf00, #ff923c);
  background-size: 200% 100%;
  transition: all 0.3s ease-in-out;
}

.submit-btn .input-data:hover .inner{
  background-position: right;
}

.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@media (max-width: 920px) {
  .containerContact .text{
    font-size: 30px;
  }

  .containerContact form{
    padding: 10px 0 0 0;
  }

  .containerContact form .form-row{
    display: flex;
    flex-wrap: wrap;
  }

  form .form-row .input-data{
    margin: 15px 0!important;
  }

  .submit-btn .input-data{
    width: 30%!important;
  }
}