.body-container {
    position: relative;
    margin-left: 30px;
  }
  .timeline-item--no-children .body-container {
    background: transparent;
    box-shadow: none;
  }
  .body-container:after {
    content: "";
    display: table;
    clear: both;
  }
  .timeline-item-date {
    position: absolute;
    top: -12px;
    left: 0;
    background: #ddd;
    padding: 1px;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 52px;  
    box-sizing: border-box;
    width: 90%;
  }
  .timeline-item-dateinner {
    background: #e86971;
    color: #fff;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    margin: 0;
    border-right-color: transparent;
    -webkit-clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    clip-path: polygon(0% 0%, 95% 0%, 100% 50%, 95% 100%, 0% 100%);
    height: 50px;
    width: 100%;
    display: block;
    line-height: 52px;
    text-indent: 15px;
  }
  
  .timeline-item--no-children .body-container::before {
    display: none;
  }
  .entry {
    clear: both;
    text-align: left;
    position: relative;
  }
  .timeline--animate .entry .is-hidden {
    visibility: hidden;
  }
  .timeline--animate .entry .bounce-in {
    visibility: visible;
    -webkit-animation: bounce-in 0.4s;
    -moz-animation: bounce-in 0.4s;
    animation: bounce-in 0.4s;
  }
  .entry .title {
    margin-bottom: 0.5em;
    float: left;
    width: 34%;
    position: relative;
    height: 32px;
  }
  .entry .title:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border: 4px solid;
    background-color: #ffffff;
    border-radius: 100%;
    top: 15%;
    right: -8px;
    z-index: 99;
    box-sizing: content-box;
  }
  .entry .body {
    margin: 0 0 3em;
    float: right;
    width: 66%;
    color: #333;
  }
  .entry .body p {
    line-height: 1.4em;
  }
  .entry .body h1, .entry .body h2, .entry .body h3, .entry .body h4, .entry .body h5, .entry .body h6 {
    margin: 0;
  }
  .entry .body p:first-child {
    margin-top: 0;
    font-weight: 400;
  }
  @-o-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }
  
  @-webkit-keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
    }
  }
  @-moz-keyframes bounce-in {
    0% {
      opacity: 0;
      -moz-transform: scale(0.5);
    }
  
    100% {
      -moz-transform: scale(1);
    }
  }
  @keyframes bounce-in {
    0% {
      opacity: 0;
      -webkit-transform: scale(0.5);
      -moz-transform: scale(0.5);
      -ms-transform: scale(0.5);
      -o-transform: scale(0.5);
      transform: scale(0.5);
    }
  
    100% {
      -webkit-transform: scale(1);
      -moz-transform: scale(1);
      -ms-transform: scale(1);
      -o-transform: scale(1);
      transform: scale(1);
    }
  }
  
  @media only screen and (max-width: 768px) {
    .entry .title {
      float: left;
      width: 70%;
    }
    .timeline-item-date {
      margin-left: 30px;
    }
    .entry .title:before {
      top: 15%;
      left: 3px;
      right: auto;
      z-index: 99;
    }
    .entry .body {
      margin: 20px 0 3em;
      float: right;
      width: 99%;
    }
  }