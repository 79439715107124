#accueil {
    height: 100vh;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
  
#accueil .image, #accueil .texte {
    flex: 1 1 100%;
    text-align: center;
    margin: 20px 0;
    max-width: 900px;
}

#accueil .image {
    margin-top: 100px;
}
  
#accueil .image img {
    max-width: 100%;
    height: auto;
}
