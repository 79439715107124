.timeline--wrapper {
    width: calc(100% - 24px);
    padding: 12px;
  }
  .timeline {
    width: 100%;
    max-width: 800px;
    padding: 15px 0 0  0;
    position: relative;
    margin: 50px auto;
  }
  .timeline:before {
    content: "";
    position: absolute;
    top: 0px;
    left: calc(33% + 6px);
    bottom: 0px;
    width: 0px;
    border: 2px solid;
  }
  .timeline:after {
    content: "";
    display: table;
    clear: both;
  }
  @media only screen and (max-width: 768px) {
    .timeline:before {
      left: calc(1% + 6px);
    }
  }